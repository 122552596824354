<!--
 * @Description:
 * @Author: seep
 * @Date: 2019-08-15 16:37:55
 * @LastEditTime: 2019-08-16 13:13:50
 -->
<template>
    <div v-loading="loading" ref="content" class="course-center">
        <div class="content">
            <div v-if="isShowClassTree" class="class-menu">
                <div class="title"><span class="ver-line" /><span class="name">课程分类</span><div class="reset-button" @click="resetCheckData">重置</div></div>
                <el-tree
                    v-if="treeData.length>0"
                    ref="tree"
                    :data="treeData"
                    :expand-on-click-node="false"
                    show-checkbox
                    node-key="id"
                    @check="getCheckData"
                >
                    <span slot-scope="{ node,data }" class="custom-tree-node">
                        <span>{{ data.name }}</span>
                    </span>
                </el-tree>
            </div>
            <div v-if="isShowClassTree" style="width:10px;background-color:#F7F9FA;height:100%;display:inline-block" />
            <div :style="{width:isShowClassTree===false?'calc(100% - 10px)':'calc(100% - 250px)'}" class="right">
                <div :style="{width:width}" class="sub-header">
                    <i :class="isShowClassTree===false?'safety-icon-indent':'safety-icon-outdent'" class="showIcon" @click="isShowClassTree=!isShowClassTree" />
                    <!-- <span :style="{color:Number(orderType)===1?'#4c85ff':'#B3B3B3'}" @click="orderType=1">按更新时间顺序</span>
                    <span :style="{color:Number(orderType)===2?'#4c85ff':'#B3B3B3'}" @click="orderType=2">按浏览次数排序</span>
                    <span :style="{color:Number(orderType)===4?'#4c85ff':'#B3B3B3'}" @click="orderType=4">按收藏次数排序</span> -->
                    <el-input
                        v-model="name"
                        class="search"
                        suffix-icon="el-icon-search"
                        placeholder="请搜索课程名称"
                    />
                </div>
                <el-scollbar :style="{marginLeft:contentLeft+'px'}" class="sub-content">
                    <div
                        v-for="(item,index) in currentCourseDataList"
                        :key="index"
                        :style="{marginLeft:(index+1)%geshu===1?'10px':'10px'}"
                        class="course-li"
                        @click="goDetail(item.id)"
                    >
                        <div class="div-cover-img">
                            <img :src="item.image|formatCourseImg" class="cover-img" />
                        </div>

                        <div class="sub-middle-li">
                            <div class="course-name">{{ item.title }}</div>
                            <!-- <div class="course-time">发布时间: {{ item.updateTime }}</div> -->
                            <div class="other-info">
                                {{ item.chapter_num }}讲
                                <div class="rate">
                                    <!-- <el-rate
                                        :value="Number(item.commentScore)/2"
                                        :colors="['#FD583E','#FD583E','#FD583E']"
                                        disabled
                                        text-color="#FD583E"
                                    /> -->
                                    <span>收藏数：{{ item.collect_num }}</span>
                                    <!-- <img class="remark-img" :src="Number(item.is_option)===0?require('../assets/xuanxiu.png'):require('../assets/bixiu.png')"> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="sub-bottom-li" style="border-bottom:0px;">
                            <div class="lecturer-info">
                                <img :src="item.lecturerAvatar" />

                                <span>{{ item.lecturerName }}</span>
                                <div>
                                    <i class="i-1 safety-icon-love-line">&nbsp;{{ item.collectNum|formatNum }}</i>
                                    <i class="i-2 safety-icon-fire">{{ item.clickNum|formatNum }}</i>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <img v-if="currentCourseDataList.length===0" style="width:251px;height:200px;margin-left:calc(50% - 125px);margin-top:150px;" src="../assets/blank.png" />
                    <div v-if="currentCourseDataList.length===0" style="width:251px;height:200px;margin-left:calc(50% - 125px);color:#666666;font-size:16px;font-weight:400;text-align:center">暂无内容</div>
                </el-scollbar>
                <el-pagination
                    v-if="currentCourseDataList.length!=0"
                    :current-page="page"
                    :page-sizes="[20, 50, 100]"
                    :page-size="perPage"
                    :total="Number(total)"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components:{
    },
    filters:{
        formatCourseImg (val) {
            if(val === ''){
                return require('../assets/fengmian.png');
            }
            return val;
        },
        formatNum(val){
            return Number(val) > 999 ? '999+' : val;
        },
        formatScore(val){
            return Number(val).toFixed(1);
        },
    },
    data() {
        return {
            // 列表请求的参数
            isPaging:true,
            page:1,
            perPage:20,
            total:0,
            belong:2,
            categoryIds:[],
            currentCourseDataList:false,
            // 分类树数据
            treeData:[],
            // 是否显示左侧分类树
            isShowClassTree:true,
            // 当前排序规则1默认 2学习 3收藏
            orderType:1,
            // 搜索名称
            name:'',
            // 一行展示多少
            geshu:5,
            screenWidth: document.body.clientWidth,
            // 搜索框right
            width:'calc(100% + 10px)',
            contentLeft:-10,
            loading:false,
        };
    },
    computed: {
    },
    watch: {
        orderType(){
            this.page = 1;
            this.getData();
        },
        name(){
            this.page = 1;
            this.getData();
        },
        screenWidth(){
            this.initBuJu();
        },
        isShowClassTree () {
            this.initBuJu();
        },
    },
    beforeMount() {
        
    },
    mounted() {
        if(this.$route.query.cateId){
            this.categoryIds = [Number(this.$route.query.cateId)];
        }
        const screenHeight = window.innerHeight;
        this.$refs.content.style.minHeight = screenHeight - 324 + 'px';
        this.getData();
        this.getTreeData();
    },
    created() {
        this.init();
        this.initBuJu();
    },
    methods: {
        init(){
            const that = this;
            window.onresize = () => {
                return (() => {
                    window.screenWidth = document.body.clientWidth;
                    that.screenWidth = window.screenWidth;
                })();
            };
        },
        initBuJu(){
            this.screenWidth >= 1600 && this.isShowClassTree === false && (this.geshu = 5) && (this.width = '1381px') && (this.contentLeft = 9);
            this.screenWidth >= 1600 && this.isShowClassTree === true && (this.geshu = 6) && (this.width = '1150px') && (this.contentLeft = 5);
            this.screenWidth < 1600 && this.isShowClassTree === false && (this.geshu = 4) && (this.width = '1151px') && (this.contentLeft = 16);
            this.screenWidth < 1600 && this.isShowClassTree === true && (this.geshu = 5) && (this.width = '918px') && (this.contentLeft = 10);
        },
        async getData(){
            const params = {
                course_cate:this.categoryIds,
                title: this.name,
                page: this.page,
                limit: this.perPage,
                order: this.orderType === 1?'update_time':this.orderType === 2?'click_num':'collect_num',
            }
            this.loading = true;
            const res = await this.$http.get('/api/course/getCourseList',params);
            this.loading = false;
            if(res){
                this.currentCourseDataList = res.list;
                this.total = res.total;
            }
        },
        handleSizeChange(val){
            this.perPage = val;
            this.page = 1;
            this.getData();
        },
        handleCurrentChange(val){
            this.page = val;
            this.getData();
        },
        async getTreeData(){
            const res = await this.$http.get('/api/course/getCourseCate');
            if(res){
                this.treeData = res;
                console.log(this.categoryIds,'this.categoryIds');
                if(this.categoryIds.length > 0){
                    const tem = setTimeout(() => {
                        this.$refs.tree.setCheckedKeys(this.categoryIds);
                        clearTimeout(tem);
                    }, 100);
                    
                }
            }
        },
        getCheckData() {
            const temArr = this.$refs.tree.getCheckedKeys(true);
            this.$refs.tree.setCheckedKeys([]);
            if(this.categoryIds.length === 0){
                this.categoryIds = temArr;
            }else{
                temArr.splice(temArr.indexOf(this.categoryIds[0]),1);
                this.categoryIds = temArr;
            }
            const tem = setTimeout(() => {
                this.$refs.tree.setCheckedKeys(this.categoryIds);
                this.getData();
                clearTimeout(tem);
            }, 100);
            
        },
        resetCheckData() {
            this.$refs.tree.setCheckedKeys([]);
            this.categoryIds = [];
            this.getData();
        },
        // 跳转至详情页
        goDetail(id){
            this.$router.push(`/courseDetail?id=${id}`);
        },
    },
};
</script>
<style lang="scss" scoped>
.course-center{
    // min-width: 1200px;
    // min-height: 900px;
    @media only screen and (min-width:1366px) {
        min-width:1200px;
    }
    @media only screen and (min-width:1600px) {
        min-width:1420px;
    }
    background: #F7F9FA;
    height:calc(100% - 10px);
    padding-top: 10px;
    .content{
        height:100%;
        // width:calc(100% - 40px);
        @media only screen and (min-width:0px) {
            width:1200px;
        }
        @media only screen and (min-width:1600px) {
            width:1420px;
        }
        // width:1160px;
        // padding: 0 20px;
        margin: auto;
        background: #fff;
        font-size: 0px;
        .class-menu{
            ::v-deep.el-tree {
            width: calc(100% - 20px);
            margin-left: 10px;
            overflow-x: auto;
            padding-bottom: 15px;
            }

            ::v-deep.el-tree>.el-tree-node {
            display: inline-block;
            min-width: 100%;
            }
            ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner{
                background-color: #4c85ff;
                border-color: #4c85ff;
            }
            ::v-deep.el-checkbox__input.is-focus .el-checkbox__inner{
                border-color: #4c85ff;
            }
            width:230px;
            height:100%;
            display: inline-block;
            vertical-align: top;
            color:#454545;
            font-size: 14px;
            overflow: auto;
            .title{
                color:#333333;
                font-weight: bold;
                font-size: 15px;
                padding: 26px 10px;
                position: relative;
                .ver-line{
                    display: inline-block;
                    vertical-align: middle;
                    width: 5px;height:16px;
                    background-color: #4C85FF;
                }
                .name{
                    vertical-align: middle;
                    display: inline-block;
                    margin-left: 10px;
                }

                .reset-button{
                    cursor: pointer;
                    position: absolute;
                    text-align: center;
                    width:60px;
                    height:30px;
                    background:rgba(252,252,252,1);
                    border:1px solid rgba(242,242,242,1);
                    border-radius:2px;
                    font-weight: 400;
                    color:#808080;
                    font-size: 14px;
                    line-height: 30px;
                    right: 10px;
                    top: 19px;
                    &:hover{
                        background-color: #7FA8FF;
                        color:#fff;
                    }
                    &:active{
                        background-color: #4c85ff;
                    }
                }
            }
        }
        .right{
            display: inline-block;
            vertical-align: top;
            position: relative;
            height: 100%;
            .sub-header{
                position: relative;
                width:100%;
                height:67px;
                color:#999999;
                line-height: 65px;
                padding-left: 10px;
                margin: auto;
                .showIcon{
                    font-size: 22px;
                    vertical-align: middle;
                }
                span{
                    vertical-align: middle;
                    font-size: 14px;
                    margin-left: 40px;
                    cursor: pointer;
                }
                .search{
                    width:280px;
                    position: absolute;
                    right:0px;
                    ::v-deep.el-input__inner{
                            height:32px;
                            padding: 2px 15px;
                            height: 30px;
                            border: 1px solid #DBDBDB;
                            border-radius: 15px;
                            color: #B3B3B3;
                            font-size: 14px;
                        }
                        .el-input__icon{
                            line-height: 30px;
                        }
                }
            }
            .sub-content{
                width:calc(100% + 30px);
                padding-bottom: 42px;
                ::v-deep .el-scrollbar__wrap{
                    overflow-x: auto;
                }
                ::v-deep.el-scrollbar__bar.is-vertical{
                    display: none;
                }
                .course-li{
                    width: 220px;
                    height: 200px;
                    border-radius: 2px;
                    display: inline-block;
                    margin-bottom: 25px;
                    border: 1px solid #f2f1f1;
                    cursor: pointer;
                    overflow: hidden;
                    vertical-align: top;
                    .div-cover-img{
                        width:100%;
                        height:123px;
                        overflow: hidden;
                        .cover-img{
                            width:100%;
                            height:123px;
                            transition: transform 1s;
                            &:hover{
                                transform: scale(1.15);
                            }
                        }
                    }
                    .sub-middle-li{
                        height:84px;
                        width:100%;
                        border-bottom:1px solid rgba(242,241,241,1);
                        .course-name{
                            padding: 14px 9px;
                            color:#333333;
                            font-weight: 400;
                            font-size: 13px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                        .course-time {
                            font-size: 12px;
                            position: relative;
                            padding-left: 9px;
                            padding-bottom: 8px;
                            color: #808080;
                        }
                        .other-info{
                            color:#A6A6A6;
                            font-size: 12px;
                            position: relative;
                            padding-left: 9px;
                            ::v-deep.el-rate{
                                display: inline-block;
                                vertical-align: middle
                                // position: absolute;
                                // top:-3px;
                                // right:25px;
                            }
                            ::v-deep.el-rate__icon{
                                margin-right: 2px;
                            }
                            .rate{
                                position: absolute;
                                top:-4px;
                                right:10px;
                                .remark-img{
                                    position: absolute;
                                    height: 56px;
                                    right: -7px;
                                    top: -40px;
                                }
                                span{
                                    vertical-align: middle;
                                    // color:#FD583E;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                    .sub-bottom-li{
                        height:34px;
                        width: 100%;
                        border-bottom:1px solid rgba(242,241,241,1);
                        .lecturer-info{
                            position: relative;
                            img{
                                border-radius: 50%;
                                vertical-align: middle;
                                height:20px;
                                margin-left: 9px;
                                margin-top: 7px;
                            }
                            span{
                                color:#333333;
                                font-size: 12px;font-weight: 400;
                                vertical-align: middle;
                                display: inline-block;
                                width:85px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin-top: 7px;
                                margin-left: 12px;
                            }
                            div{
                                position: absolute;top: 11px;right:5px;
                            }
                            i{
                                color:#A6A6A6;font-size:12px;
                            }
                            .i-1{
                                margin-right:4px;
                            }
                        }
                    }
                }

            }
            ::v-deep.el-pagination {
                    position: absolute;
                    text-align: right;
                    bottom: 20px;
                    padding: 0 10px;
                    width: calc(100% - 20px);
                    background: #fff;
                    .el-pagination__total{
                        position: absolute;
                        left:10px;
                    }
                    .el-pagination__sizes{
                        position: absolute;
                        left:60px;
                    }
                    .btn-prev{
                        margin-left: 320px;
                    }
                }
        }

    }

}
</style>
