<template>
    <div>
        <Header/>
        <Course />
        <Footer/>
    </div>
</template>
<script>
import Course from './course'
import { mapState } from 'vuex'
export default {
    components:{
        Course,
    },
    computed:mapState([
        'userInfo',
    ]),
}
</script>
